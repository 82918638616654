import { notifyWindowEvent } from "modules/picasso-ui/state/windowEventUtils"
import { changeOld, getBacklog, getBacklogIdByAlias, getBacklogInfo, getBacklogType, getEac, getSharingData } from "modules/plinzip/api/backlogApi"
import { useSignedIn } from "modules/react-auth"
import { hasPermissionInEac } from "modules/react-auth/component/border/PermissionEntityBorder"
import { useGuestIdentityOld } from "modules/react-auth/useGuestIdentityOld"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import useSWR, { SWRConfiguration, SWRResponse, useSWRConfig } from "swr"
import { isHavingValue, isNullOrUndefined, notNullNotUndefined } from "utils/objectUtils"
import { isUUID } from "utils/stringUtils"
import { handleResponseDataGetContent } from "utils/useResolvedV2"
import { BacklogInfoDto, BacklogMasterData, BacklogsAccessGroup, BacklogType, RankingEvent, RankingSharingData } from "../backlog/rating/RankingTypes"
import { LayoutScreenContentCentered } from "components/layout/positioning/LayoutScreenContentCentered"
import { EmptyStateScreen } from "modules/picasso-ui/present/emptyState/EmptyStateScreen"
import { ApiRequestOptions } from "apiClient/common/YoioApiRequestTypes"


// backlogId

export type BacklogContextIdProps = {
    backlogId: string
}

export const BacklogIdContext = createContext<BacklogContextIdProps>(null)

export const useBacklogIdContext = () => {
    return useContext(BacklogIdContext)
}

export const BacklogIdProvider = ({children, slug}) => {

    const idResolved = useSWR(slug && !isUUID(slug) ? `rankings/${slug}/id`:null, ()=>getBacklogIdByAlias(slug), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
  
    const backlogId = isUUID(slug) ? slug : idResolved.data
  
    const backlogIdContextValue = useMemo(()=>{
        return {backlogId}
    },[backlogId])
  
    if (!backlogId) {
      return null
    }

    return <BacklogIdContext.Provider value={backlogIdContextValue}>{children}</BacklogIdContext.Provider>
}


// eac

export const BacklogEacContext = createContext<any>(null)

export const useBacklogEacContext = () => {
    return useContext(BacklogEacContext)
}


// master data

export const getBacklogVersionFetchKey = (backlogId) => {
    notNullNotUndefined(backlogId)
    return `/rankings/${backlogId}/version`
}

export const getBacklogMasterDataFetchKey = (backlogId) => {
    notNullNotUndefined(backlogId)
    return `/rankings/${backlogId}`
}

export const getBacklogInfoFetchKey = (backlogId) => {
    notNullNotUndefined(backlogId)
    return `/rankings/${backlogId}/info`
}

export const useBacklogVersionSWR = (backlogId?: string, options?: SWRConfiguration) => {

    const { data: eac } = useBacklogEacSwr(backlogId)

    const swr = useSWR<{new: boolean, version: number}>(backlogId && eac && hasPermissionInEac(eac, 'backlogView') ? getBacklogVersionFetchKey(backlogId):null, ()=>{
        return getBacklog(backlogId).then(data=>{
            if (!data) {
                return {}
            }
            return { new: data.new, version: data.version }
        })
    }, options)

    /**
     * Render
     */

    return swr
}

export const useBacklogMasterData = (eacParam?:any) => {

    const { backlogId } = useBacklogIdContext()

    const eacSwr = useBacklogEacSwr(backlogId)

    const eac = eacParam ? eacParam : eacSwr.data

    const { data } = useSWR<BacklogMasterData>(eac && hasPermissionInEac(eac, 'backlogView') ? getBacklogMasterDataFetchKey(backlogId):null, ()=>{
        return getBacklog(backlogId).then(data=>{
            if (!data) {
                return {}
            }
            const { backlogId, name, accessSettings, hasJiraIntegration, quotas, quotasReached, meIsInParticipants, isDemo, showPromotions, type } = data
            return { backlogId, name, accessSettings, hasJiraIntegration, quotas, quotasReached, meIsInParticipants, isDemo, showPromotions, type } as BacklogMasterData
        })
    })

    /**
     * Render
     */

    return {
        data: (data || null) as BacklogMasterData,
    }
}

export interface RankingsApiProps {
    gotoExport: (backlogId: string, name: string, type: string, filter?: any) => void
    changeName: (backlogId: string, name: string) => Promise<any>
}

export const useRankingsApi = (): RankingsApiProps => {

    const { mutate } = useSWRConfig()

    const gotoExport = (backlogId: string, name: string, type: string, filter?: any) => {
        notNullNotUndefined(backlogId)
        notNullNotUndefined(name)
        notNullNotUndefined(type)

        notifyWindowEvent(RankingEvent.rankingExportCalled, {
            backlogId,
            name,
            type,
            filter
        })
    }

    const changeName = (backlogId: string, name: string) => {
        notNullNotUndefined(backlogId)
        notNullNotUndefined(name)

        const updateFn = ()=>changeOld({
            backlogId,
            data: {
              name
            }
        })

        const options = {
            populateCache: false,
            optimisticData: (current) => {
                return { ...current, name}
            },
        }

        return mutate(getBacklogMasterDataFetchKey(backlogId), updateFn, options)
            .then(()=>mutate(getBacklogInfoFetchKey(backlogId)))
    }



    return { gotoExport, changeName }
}

const getBacklogEacFetchKey = (backlogId: string) => {
    notNullNotUndefined(backlogId)
    return `/rankings/${backlogId}/eac`
}

export const useBacklogEacSwr = (backlogId) => {

    const { cache } = useSWRConfig()

    const swr = useSWR(backlogId ? getBacklogEacFetchKey(backlogId):null, ()=>{
        return getEac(backlogId, { supressErrorMessage: true } as ApiRequestOptions).then(handleResponseDataGetContent)
    }, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: isHavingValue(backlogId) && isNullOrUndefined(cache.get(getBacklogEacFetchKey(backlogId)))
    })

    return swr
}

export const BacklogEacProvider = ({children, onChange}) => {
   
    /**
     * Hooks
     */

     const { createGuestIdentity, hasGuestIdentitySigned } = useGuestIdentityOld()
    
     const { me } = useSignedIn()

    const { backlogId } = useBacklogIdContext()

    const { data: eac, mutate, error } = useSWR(getBacklogEacFetchKey(backlogId), ()=>{
        return getEac(backlogId, { supressErrorMessage: true } as ApiRequestOptions).then(handleResponseDataGetContent)
    })

    const { data: backlogMasterData } = useBacklogMasterData(eac)
    const { accessSettings, type } = backlogMasterData || {}

    const [ready, setReady] = useState<boolean>()

    /**
     * Events
     */

    useEffect(()=>{
        if (!eac) {
            return
        }

        onChange?.(eac)

        if (!accessSettings) {
            if (eac && !hasPermissionInEac(eac, 'backlogView')) {
                setReady(true)
            }
            return
        }
        
        if (type === 'JIRA') {
            if (eac) {
                if (!eac.isWorkspaceMember) {
                    
                    const allUsersAccessGroup = accessSettings?.allUsersAccessGroup 
                    const requiresGuestIdentity = allUsersAccessGroup && [BacklogsAccessGroup.rater, BacklogsAccessGroup.raterWithResults, BacklogsAccessGroup.editor].includes(allUsersAccessGroup)

                    if (requiresGuestIdentity && !hasGuestIdentitySigned()) {
                        createGuestIdentity()
                            .then(()=>{
                                return me.reloadMe()
                            })
                            .then(()=>{
                                return mutate()
                            })
                    } else {
                        setReady(true)
                    }
                } else {
                    setReady(true)
                }
            }
        } else if (type === 'ROOM') {


            setReady(true)
        } else {
            throw new Error('unexpected type ' + type)
        }

    },[eac, accessSettings])

    /**
     * Render
     */

    const shouldShowNotFound = error?.apiErrorFirst?.type === 'notFound'
    if (shouldShowNotFound) {
        return (
            <LayoutScreenContentCentered>
                <EmptyStateScreen heading="Not found" helperText="The resource you requested does not exist or its access is restricted." />
            </LayoutScreenContentCentered>
        )
    }

    return <BacklogEacContext.Provider value={ready ? eac : null}>{children}</BacklogEacContext.Provider>

}

// Sharing

export const useRankingSharingData = (backlogId:string):RankingSharingData => {
    const sharingDataResolved = useSWR<RankingSharingData>(backlogId ? `/rankings/${backlogId}/sharingData/`:null, ()=>getSharingData(backlogId), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    return sharingDataResolved.data
}

export const useBacklogType = (backlogId?:string):BacklogType => {
    const typeSwr = useSWR<BacklogType>(backlogId ? `/rankings/${backlogId}/type`:null, ()=>getBacklogType(backlogId).then(handleResponseDataGetContent), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    return typeSwr?.data
}

export const useBacklogInfo = (backlogId?:string) => {
    const swr = useBacklogInfoSwr(backlogId)
    return swr.data
}

export const useBacklogInfoSwr = (backlogId?:string):SWRResponse<BacklogInfoDto> => {
    const swr = useSWR<BacklogInfoDto>(backlogId ? getBacklogInfoFetchKey(backlogId):null, ()=>getBacklogInfo(backlogId).then(handleResponseDataGetContent), {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })
    return swr
}


